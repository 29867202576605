import { Controller } from "@hotwired/stimulus";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

import "photoswipe/dist/photoswipe.css";

class UsePhotoSwipe {
    lightbox;

    constructor(controller, options) {
        let element = options.element;
        if (element === undefined) {
            element = controller.element;
        }

        const imageContainer = document.createElement("div");
        imageContainer.className = "pswp__zoom-container";

        // https://photoswipe.com/options/
        const pswpOptions = options.photoswipe;
        const lightbox = new PhotoSwipeLightbox({
            gallery: element,
            children: "a",
            pswpModule: PhotoSwipe,
            clickToCloseNonZoomable: false,
            zoomEl: imageContainer,
            maxZoomLevel: 2,
            ...pswpOptions,
        });

        lightbox.init();
        this.lightbox = lightbox;
    }

    destroy() {
        if (this.lightbox !== undefined) {
            this.lightbox.destroy();
            this.lightbox = undefined;
        }
    }
    parseImageDimensions(item) {
        const width = parseInt(item.getAttribute("data-pswp-width")) || 0;
        const height = parseInt(item.getAttribute("data-pswp-height")) || 0;

        return { w: width, h: height };
    }

    generateItemsFromGallery(element) {
        return Array.from(element.children).map((child, index) => {
            const a = child.tagName === "A" ? child : child.querySelector("a");
            if (!a) return null;

            const src = a.getAttribute("href");
            const size = this.parseImageDimensions(a);

            return {
                src,
                w: size.w,
                h: size.h,
            };
        });
    }
}

export class LightboxController extends Controller {
    lightbox;
    pswpOptionsValue;
    usePhotoSwipe;

    connect() {
        const element = this.element;

        const pswpOptions = this.pswpOptionsValue;
        this.usePhotoSwipe = new UsePhotoSwipe(this, {
            element,
            photoswipe: pswpOptions,
        });

        this.lightbox = this.usePhotoSwipe.lightbox;
    }

    loadAndOpen(e) {
        const currentTarget = e.currentTarget;
        const slideIndex = parseInt(currentTarget.dataset.pswpSlideIndex || "0");
        const gallery = this.element;

        const items = this.usePhotoSwipe.generateItemsFromGallery(gallery);

        if (items.length > 0) {
            this.lightbox?.loadAndOpen(slideIndex, {
                gallery: items,
            });
        }
    }
}

LightboxController.values = {
    pswpOptions: Object,
};

export default LightboxController;
