import { Controller } from "@hotwired/stimulus";

export class ModalController extends Controller {
    static targets = ["modal", "backdrop", "formMessages"];

    connect() {
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        document.addEventListener("click", this.handleDocumentClick);

        const openOnLoad = this.data.get("openOnLoad") !== "false";

        if (openOnLoad && this.data.get("modalOpenOnLoad") !== "false") {
            this.openModal();
        }

    }

    disconnect() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    handleDocumentClick(event) {
        if (!this.hasModalTarget || !this.hasBackdropTarget) {
            console.error("Missing target elements for ModalController");
            return;
        }

        if (!this.modalTarget.classList.contains("hidden")) {
            if (event.target === this.modalTarget || event.target.closest("[data-action='modal#closeModal']")) {
                this.closeModal();
            }
        }
    }

    async openModal() {
        this.modalTarget.style.opacity = 0;
        this.backdropTarget.style.opacity = 0;

        this.modalTarget.style.transition = "opacity 0.3s ease-in-out";
        this.backdropTarget.style.transition = "opacity 0.3s ease-out";

        this.modalTarget.classList.remove("hidden");
        this.backdropTarget.classList.remove("hidden");

        await Promise.all([this.animateOpacity(this.modalTarget, 1, 300), this.animateOpacity(this.backdropTarget, 1, 300)]);

        document.body.classList.add("overflow-hidden");
    }

    async closeModal() {
        await Promise.all([this.animateOpacity(this.modalTarget, 0, 300), this.animateOpacity(this.backdropTarget, 0, 300)]);

        this.modalTarget.classList.add("hidden");
        this.backdropTarget.classList.add("hidden");

        document.body.classList.remove("overflow-hidden");

        const successMessageContainer = document.getElementById("success-message-container");
        if (successMessageContainer) {
            successMessageContainer.innerHTML = "";
        }

        const errorMessageContainer = document.getElementById("error-message-container");
        if (errorMessageContainer) {
            errorMessageContainer.innerHTML = "";
        }
    }

    async animateOpacity(element, targetOpacity, duration) {
        return new Promise((resolve) => {
            element.style.transition = `opacity ${duration / 1000}s ease-in-out`;
            element.style.opacity = targetOpacity;

            setTimeout(() => {
                element.style.transition = "";
                resolve();
            }, duration);
        });
    }

    get backdropTarget() {
        return this.targets.find("backdrop");
    }

    get formMessagesTarget() {
        return this.targets.find("formMessages");
    }
}
