import { Controller } from '@hotwired/stimulus';

export class HeaderController extends Controller {
  static targets = ['menu', 'sidebar', 'overlay', 'navItem', 'sidebarButton'];
  static values = {
    color: { type: String, default: 'text-white' },
  };

  textColorBlackClass = 'text-black';
  darkTextColorWhiteClass = 'dark:text-white';
  darkTextColorBlackClass = 'dark:text-black';
  activeTextColorClass = 'text-red-600';

  connect() {
    this.textColorWhiteClass = this.colorValue;

    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.updateHeaderBackground();
    this.updateTextColor();
    this.updateSidebarButtonStyle();
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    this.updateHeaderBackground();
    this.updateTextColor();
    this.updateSidebarButtonStyle();
  }

  updateHeaderBackground() {
    var header = document.querySelector('header');
    var scrollPosition = window.scrollY;

    if (scrollPosition > 50) {
      header.classList.add('bg-white', 'dark:bg-gray-800', 'shadow-lg');
    } else {
      header.classList.remove('bg-white', 'dark:bg-gray-800', 'shadow-lg');
    }
  }

  updateTextColor() {
    const navItems = this.navItemTargets;
    const scrollPosition = window.scrollY;
    const activeTextColorClass = this.activeTextColorClass;
    const textColorWhiteClass = this.textColorWhiteClass;
    const textColorBlackClass = this.textColorBlackClass;
    const darkTextColorWhiteClass = this.darkTextColorWhiteClass;

    const currentUrl = window.location.href;

    navItems.forEach((item) => {
      const itemUrl = item.getAttribute('href');
      const isActive = item.classList.contains(activeTextColorClass);

      if (currentUrl.includes(itemUrl)) {
        item.classList.add(activeTextColorClass, 'dark:text-red-500');
      } else {
        item.classList.remove(activeTextColorClass, 'dark:text-red-500');
      }

      if (scrollPosition > 50) {
        item.classList.remove(textColorWhiteClass, darkTextColorWhiteClass);
        item.classList.add('text-gray-700', darkTextColorWhiteClass);
      } else {
        item.classList.remove('text-gray-700', darkTextColorWhiteClass);
        item.classList.add(
          isActive ? activeTextColorClass : textColorWhiteClass,
          darkTextColorWhiteClass,
        );
      }
    });
  }

  updateSidebarButtonStyle() {
    const sidebarButton = this.sidebarButtonTarget;
    const scrollPosition = window.scrollY;
    const darkTextColorWhiteClass = this.darkTextColorWhiteClass;
    const textColorWhiteClass = this.textColorWhiteClass;

    if (scrollPosition > 50) {
      sidebarButton.classList.remove(
        textColorWhiteClass,
        darkTextColorWhiteClass,
      );
      sidebarButton.classList.add('text-gray-700', 'dark:text-white');
    } else {
      sidebarButton.classList.remove('text-gray-700', 'dark:text-white');
      sidebarButton.classList.add(textColorWhiteClass, darkTextColorWhiteClass);
    }
  }

  showSidebar() {
    this.sidebarTarget.classList.remove('hidden');
    this.sidebarTarget.classList.add('animate-enter');
    document.body.classList.add('overflow-y-hidden');
    this.overlayTarget.classList.remove('hidden');
  }

  hideSidebar() {
    this.sidebarTarget.classList.add('hidden');
    this.sidebarTarget.classList.remove('animate-enter');
    document.body.classList.remove('overflow-y-hidden');
    this.overlayTarget.classList.add('hidden');
  }

  openMenuWithOverlay() {
    this.showSidebar();
  }

  closeMenuWithOverlay() {
    this.hideSidebar();
  }
}
