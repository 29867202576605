import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";

export class CarouselController extends Controller {
    connect() {
        super.connect();
        this.swiper = new Swiper(this.element, {
            loop: true,
            slidesPerView: this.slideValue,
            centeredSlides: true,
            speed: this.speedValue,
            spaceBetween: this.spaceValue,
            autoplay: {
                delay: this.delayValue,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: ".slider-button-next",
                prevEl: ".slider-button-prev",
            },
            modules: [Navigation, Autoplay],
            ...this.optionsValue,
        });

        this.swiper.update();
    }
    disconnect() {
        this.swiper.destroy();
    }
}
CarouselController.values = {
    speed: { type: Number, default: 1200 },
    delay: { type: Number, default: 1200 },
    slide: { type: Number, default: 1 },
    space: { type: Number, default: 0 },
};
